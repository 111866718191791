<template>
    <el-row ref="viewerContainer" class="demo-viewer">
        <vc-config-provider :locale="locale">
            <vc-viewer :show-credit="showCredit" @ready="onViewerReady" @layer-Added="onlayerAdded">
                <!-- 放大縮小控制元件 -->
                <!--<vc-navigation-sm position="left" :compass-opts="{ autoHidden: false }" :zoom-opts="{ autoHidden: false }"></vc-navigation-sm>-->
                <!-- 修改量算風格和精度 -->
                <vc-measurements ref="measurementsRef3" position="bottom-left" :main-fab-opts="measurementFabOptions3"
                    :distance-measurement-opts="distanceMeasurementOpts3" @ready="drawingsReadyDefault"
                    :component-distance-measurement-opts="componentDistanceMeasurementOpts3"
                    :point-measurement-opts="pointMeasurementOpts3" :editable="editable" :offset="[10, 40]">
                </vc-measurements>
                <!--<vc-overlay-html ref="vcHtml" :position="[121.632533, 24.022580, 20]" :show="show">
                                <div class="vc-box">載入3D模型中....</div>
                            </vc-overlay-html>-->
                <!--<vc-primitive-tileset url="https://zouyaoji.top/vue-cesium/SampleData/Cesium3DTiles/Tilesets/dayanta/tileset.json"
                                                                          @ready-promise="onTilesetReady"></vc-primitive-tileset>-->
                <!--四八高地-->
                <!-- <vc-primitive-model ref="primitive" :minimumPixelSize="128" :maximumScale="200000" :url="url"
                    :modelMatrix="modelMatrix" @before-Load="beforeLoad">
                </vc-primitive-model> -->
                <vc-layer-imagery :alpha="parseFloat(alpha)" :brightness="parseFloat(brightness)"
                    :contrast="parseFloat(contrast)" :hue="parseFloat(hue)" :saturation="parseFloat(saturation)"
                    :gamma="parseFloat(gamma)" vc-id="Bing影像地圖">
                    <!--BingMap-->
                    <!--<vc-imagery-provider-tianditu map-style="img_c" :maximum-level="17" token="436ce7e50d27eede2f2929307e6b33c0"></vc-imagery-provider-tianditu>-->
                    <!--origin setting bm-key="AmGu3cvB_g1HbkQErEyvmLc9j0YIGWS7IdOqR7-hQbO8J92Fzrzkhy_bYKSsyoEx"-->
                    <vc-imagery-provider-bing ref="providerBing"
                        bm-key="ArLXZ7BNLHhwJMSRujkXoxsXPP8hVPM5K6q0qdSSDcsaDJjV66iNvrQGD6WNtQgM" map-style="Aerial"
                        culture="zh-Hant"></vc-imagery-provider-bing>
                </vc-layer-imagery>
                <vc-layer-imagery :alpha="parseFloat(alpha)" :brightness="parseFloat(brightness)"
                    :contrast="parseFloat(contrast)" :hue="parseFloat(hue)" :saturation="parseFloat(saturation)"
                    :gamma="parseFloat(gamma)" vc-id="Arcgis影像地圖">
                    <!--Arcgis Image Map-->
                    <vc-imagery-provider-arcgis ref="providerArcgis"
                        :enable-pick-features="enablePickFeatures"></vc-imagery-provider-arcgis>
                </vc-layer-imagery>
                <vc-layer-imagery :alpha="parseFloat(alpha)" :brightness="parseFloat(brightness)"
                    :contrast="parseFloat(contrast)" :hue="parseFloat(hue)" :saturation="parseFloat(saturation)"
                    :gamma="parseFloat(gamma)" vc-id="Arcgis電子地圖">
                    <!--Arcgis Map-->
                    <vc-imagery-provider-wmts ref="providerWorld_Street_Map"
                        url="https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/WMTS/tile/1.0.0/World_Street_Map/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.jpg"
                        layer="World_Street_Map" format="image/jpeg" wmts-style="default"
                        tile-matrix-set-i-d="default028mm"></vc-imagery-provider-wmts>
                </vc-layer-imagery>
                <vc-layer-imagery :alpha="parseFloat(alpha)" :brightness="parseFloat(brightness)"
                    :contrast="parseFloat(contrast)" :hue="parseFloat(hue)" :saturation="parseFloat(saturation)"
                    :gamma="parseFloat(gamma)" vc-id="正射影像(混合)">
                    <!--正射影像(混合) 無法調亮度-->
                    <vc-imagery-provider-wmts ref="providerPHOTO_MIX" url="https://wmts.nlsc.gov.tw/wmts" layer="PHOTO_MIX"
                        format="image/jpeg" wmts-style="default"
                        tile-matrix-set-i-d="GoogleMapsCompatible"></vc-imagery-provider-wmts>
                </vc-layer-imagery>
                <vc-layer-imagery :alpha="parseFloat(alpha)" :brightness="parseFloat(brightness)"
                    :contrast="parseFloat(contrast)" :hue="parseFloat(hue)" :saturation="parseFloat(saturation)"
                    :gamma="parseFloat(gamma)" vc-id="通用電子地圖">
                    <!--臺灣通用電子地圖-->
                    <vc-imagery-provider-wmts ref="providerEMAP"
                        url="https://wmts.nlsc.gov.tw/wmts/EMAP/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png"
                        layer="EMAP" format="image/png" wmts-style="default"
                        tile-matrix-set-i-d="GoogleMapsCompatible"></vc-imagery-provider-wmts>
                </vc-layer-imagery>
                <vc-layer-imagery :alpha="parseFloat(alpha)" :brightness="parseFloat(brightness)"
                    :contrast="parseFloat(contrast)" :hue="parseFloat(hue)" :saturation="parseFloat(saturation)"
                    :gamma="parseFloat(gamma)" vc-id="通用電子地圖(透明)">
                    <!--通用電子地圖(透明)-->
                    <vc-imagery-provider-wmts ref="providerEMAP2" url="https://wmts.nlsc.gov.tw/wmts" layer="EMAP2"
                        format="image/png" wmts-style="default"
                        tile-matrix-set-i-d="GoogleMapsCompatible"></vc-imagery-provider-wmts>
                </vc-layer-imagery>
                <vc-layer-imagery :alpha="parseFloat(alpha)" :brightness="parseFloat(brightness)"
                    :contrast="parseFloat(contrast)" :hue="parseFloat(hue)" :saturation="parseFloat(saturation)"
                    :gamma="parseFloat(gamma)" vc-id="村里界(透明)">
                    <!--村里界(透明)-->
                    <vc-imagery-provider-wmts ref="providerVillage" url="https://wmts.nlsc.gov.tw/wmts" layer="Village"
                        format="image/png" wmts-style="default"
                        tile-matrix-set-i-d="GoogleMapsCompatible"></vc-imagery-provider-wmts>
                </vc-layer-imagery>
                <vcNavigation :otherOpts="otherOpts"></vcNavigation>
                <!--<vcStatusBar position="bottom-right" :offset="[130, 40]"></vcStatusBar>-->
                <!--<vc-distance-legend position="bottom-right"></vc-distance-legend>-->
                <vc-terrain-provider-cesium v-if="addTerrain"></vc-terrain-provider-cesium>
                <div id="vc-adjustBlock">
                    <table>
                        <tr>
                            <td><span>透明度</span></td>
                            <td>{{ alpha }}</td>
                            <td>
                                <input class="vc-js-slider" type="range" v-model="alpha" ref="alpha" id="alpha" min="0"
                                    max="1" step="0.01" tabindex="0">
                            </td>
                        </tr>
                        <tr>
                            <td><span>亮度</span></td>
                            <td>{{ brightness }}</td>
                            <td>
                                <input class="vc-js-slider" type="range" v-model="brightness" min="0" max="3" step="0.01"
                                    tabindex="0">
                            </td>
                        </tr>
                        <tr>
                            <td><span>對比度</span></td>
                            <td>{{ contrast }}</td>
                            <td>
                                <input class="vc-js-slider" type="range" v-model="contrast" min="0" max="3" step="0.01"
                                    tabindex="0">
                            </td>
                        </tr>
                        <tr>
                            <td><span>色調</span></td>
                            <td>{{ hue }}</td>
                            <td>
                                <input class="vc-js-slider" type="range" v-model="hue" min="-5" max="5" step="0.01"
                                    tabindex="0">
                            </td>
                        </tr>
                        <tr>
                            <td><span>飽和度</span></td>
                            <td>{{ Math.round(saturation) }}</td>
                            <td>
                                <input class="vc-js-slider" type="range" v-model="saturation" min="-100" max="100"
                                    step="0.01" tabindex="0">
                            </td>
                        </tr>
                        <tr>
                            <td><span>伽馬校正</span></td>
                            <td style="width:40px">{{ gamma }}</td>
                            <td>
                                <input class="vc-js-slider" type="range" v-model="gamma" min="0" max="10" step="0.01"
                                    tabindex="0">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <button class="vc-js-btn" v-on:click.prevent="resetDefault($event)">
                                    重設
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div id="vc-selectBlock">
                    <b>模型：</b><select class="vc-select" name="sources" id="mapLayer" placeholder="Layer"
                        v-model="selectedLayer" @change="layerChange($event)">
                        <option value="" selected>選擇模型</option>
                        <option value="A">橢圓球體</option>
                        <option value="B">立方體</option>
                        <option value="C">花蓮四八高地</option>
                        <option value="D">Mavic 3 Cine</option>
                    </select>
                    <!--<span>Selected: {{ selected }}</span>-->
                </div>
                <div id="vc-selectLayer">
                    <input type="checkbox" id="addTerrain" name="addTerrain" v-model="addTerrain">
                    <label for="addTerrain">產生3D地形</label>
                    <hr>
                    <b>可用圖層：</b>
                </div>
            </vc-viewer>
        </vc-config-provider>
    </el-row>
</template>

<script>
//export default {
//    name: 'VueCesiumApp',
//    props: {
//        msg: String
//    }
//}

//$(window).resize(function (e) {
//    //resize just happened, pixels changed
//    //console.log(window.innerHeight)
//    document.getElementById("vc-adjustBlock").style.top = (window.innerHeight - 330) + "px";
//    document.getElementById("vc-selectBlock").style.top = "20px";
//    document.getElementById("vc-selectLayer").style.top = "50px";
//});

import { DistanceUnits, AngleUnits } from 'vue-cesium'
import { ref, getCurrentInstance } from 'vue';
import { locale_enUS, locale_zhCN, locale_zhTW } from './vCesium.locale.js'

//import { Cesium } from "cesium";
var globalViewer, loadedLayer = [], gCesium;

export default {
    name: 'VueCesiumApp',
    data() {
        const alpha = ref(1)
        const brightness = ref(1)
        const contrast = ref(1)
        const hue = ref(0)
        const saturation = ref(1)
        const gamma = ref(1)
        return {
            locale: locale_zhTW,
            otherOpts: { position: 'bottom-right', offset: [0, 35] },
            selectedLayer: '',
            alpha,
            brightness,
            contrast,
            hue,
            saturation,
            gamma,
            url: 'Module/output.glb',
            modelMatrix: {},
            addTerrain: false,
            editable: false,
            clampToGround: false,
            show: true,
            showCredit: false,
            enablePickFeatures: false,
            measurementFabOptions1: {
                direction: 'right'
            },
            fullscreenElement: document.body,
            measurementFabOptions2: {
                direction: 'left',
                color: 'accent'
            },
            measurementFabOptions3: {
                direction: 'right',
                //modelValue: false,
                color: 'primary'
            },
            distanceMeasurementOpts3: {
                measureUnits: {
                    distanceUnits: DistanceUnits.METERS,
                    angleUnits: AngleUnits.DEGREES
                },
                decimals: {
                    distance: 2,
                    angle: 2
                }
            },
            componentDistanceMeasurementOpts3: {
                measureUnits: {
                    distanceUnits: DistanceUnits.METERS,
                    angleUnits: AngleUnits.DEGREES
                },
                decimals: {
                    distance: 2,
                    angle: 2
                }
            },
            pointMeasurementOpts: {
                preRenderDatas: [[108.9602, 34.21895, 500]]
            },
            areaMeasurementOpts: {
                preRenderDatas: [
                    [
                        [108.95808, 34.21955, 30],
                        [108.95948, 34.22039, 20],
                        [108.9595, 34.21914, 25]
                    ],
                    [
                        [108.955, 34.21857],
                        [108.95573, 34.21856],
                        [108.95573, 34.21761],
                        [108.95499, 34.21761]
                    ]
                ]
            },
            pointMeasurementOpts3: {
                measureUnits: {
                    distanceUnits: DistanceUnits.METERS,
                    angleUnits: AngleUnits.DEGREES_MINUTES_SECONDS,
                    slopeUnits: AngleUnits.DEGREES
                },
                decimals: {
                    lng: 3,
                    lat: 3,
                    height: 2,
                    slope: 3
                }
            },
            measurements: ['component-distance', 'polyline', 'vertical', 'area', 'point'],
            measurementFabOptions4: {
                direction: 'right'
            }
        }
    },
    methods: {
        resetDefault(e) {
            this.alpha = ref(1)
            this.brightness = ref(1)
            this.contrast = ref(1)
            this.hue = ref(0)
            this.saturation = ref(1)
            this.gamma = ref(1)
            //for (i = 0; i < loadedLayer.length; i++) {
            //    globalViewer.imageryLayers.get(loadedLayer[i]).alpha = 1;
            //    globalViewer.imageryLayers.get(loadedLayer[i]).brightness = 1;
            //    globalViewer.imageryLayers.get(loadedLayer[i]).contrast = 1;
            //    globalViewer.imageryLayers.get(loadedLayer[i]).hue = 0;
            //    globalViewer.imageryLayers.get(loadedLayer[i]).saturation = 1;
            //    globalViewer.imageryLayers.get(loadedLayer[i]).gamma = 1;
            //}
            //this.$refs.viewerRef.reload()
        },
        async layerChange(e) {
            //globalViewer.scene.primitives.removeAll();
            //primitivesCollection = primitivesCollection.destroy();
            const primitives = globalViewer.scene.primitives;
            let length = primitives.length;

            for (let i = 0; i < length; ++i) {
                //console.log('i:'+i,'length:'+length);
                const p = primitives.get(i);
                //p.show = !p.show;
                //console.log(i, p);
                if (typeof p._vcId === "undefined") {
                    globalViewer.scene.primitives.remove(p);
                    i--;
                    length--;
                    //console.log('remove i:'+i,'length:'+length);
                }
            }

            if (this.selectedLayer == 'A') {
                //建立橢圓球體
                globalViewer.scene.primitives.add(new gCesium.Primitive({
                    geometryInstances: new gCesium.GeometryInstance({
                        geometry: gCesium.EllipsoidGeometry.createGeometry(new gCesium.EllipsoidGeometry({
                            radii: new gCesium.Cartesian3(190.0, 190.0, 250.0),
                            vertexFormat: gCesium.VertexFormat.POSITION_AND_NORMAL
                        })),
                        modelMatrix: gCesium.Matrix4.multiplyByTranslation(gCesium.Transforms.eastNorthUpToFixedFrame(
                            gCesium.Cartesian3.fromDegrees(121.631459, 24.019816)), new gCesium.Cartesian3(0.0, 0.0, 300.0), new gCesium.Matrix4()),
                        id: 'ellipsoid',
                        attributes: {
                            color: gCesium.ColorGeometryInstanceAttribute.fromColor(gCesium.Color.ORANGE)
                        }
                    }),
                    appearance: new gCesium.PerInstanceColorAppearance(),
                    asynchronous: false
                }));
                globalViewer.scene.camera.flyTo({
                    destination: gCesium.Cartesian3.fromDegrees(121.629672, 24.005547, 1000),
                    orientation: {
                        heading: gCesium.Math.toRadians(10),
                        pitch: gCesium.Math.toRadians(-30),
                        roll: gCesium.Math.toRadians(0)
                    },
                });
            }
            else if (this.selectedLayer == 'B') {
                //建立立方體
                var geometry = gCesium.BoxGeometry.fromDimensions({
                    vertexFormat: gCesium.VertexFormat.POSITION_AND_NORMAL,
                    dimensions: new gCesium.Cartesian3(250.0, 250.0, 125.0)
                });

                // 几何实例化允许一个 Geometry 对象在多个对象中的位置不同的位置和独特的颜色
                var instanceBottom = new gCesium.GeometryInstance({
                    geometry: geometry,
                    modelMatrix: gCesium.Matrix4.multiplyByTranslation(gCesium.Transforms.eastNorthUpToFixedFrame(
                        gCesium.Cartesian3.fromDegrees(121.631459, 24.019816)), new gCesium.Cartesian3(0.0, 0.0, 300.0), new gCesium.Matrix4()),
                    attributes: {
                        color: gCesium.ColorGeometryInstanceAttribute.fromColor(gCesium.Color.GOLD)
                    },
                    id: 'bottom'
                });
                var instanceTop = new gCesium.GeometryInstance({
                    geometry: geometry,
                    modelMatrix: gCesium.Matrix4.multiplyByTranslation(gCesium.Transforms.eastNorthUpToFixedFrame(
                        gCesium.Cartesian3.fromDegrees(121.631459, 24.019816)), new gCesium.Cartesian3(0.0, 0.0, 800.0), new gCesium.Matrix4()),
                    attributes: {
                        color: gCesium.ColorGeometryInstanceAttribute.fromColor(gCesium.Color.CORNFLOWERBLUE)
                    },
                    id: 'top'
                });
                var instances = [instanceBottom, instanceTop];
                globalViewer.scene.primitives.add(new gCesium.Primitive({
                    geometryInstances: instances, //合并
                    // 某些外观允许每个几何图形实例分别指定某个属性，例如：
                    appearance: new gCesium.PerInstanceColorAppearance({ translucent: false, closed: true })
                }));

                globalViewer.scene.camera.flyTo({
                    destination: gCesium.Cartesian3.fromDegrees(121.629672, 24.005547, 1500),
                    orientation: {
                        heading: gCesium.Math.toRadians(10),
                        pitch: gCesium.Math.toRadians(-30),
                        roll: gCesium.Math.toRadians(0)
                    },
                });
            }
            else if (this.selectedLayer == 'C') {
                const modelC =
                    globalViewer.scene.primitives.add(await gCesium.Model.fromGltfAsync({
                        url: "Module/output.glb",
                        show: true,
                        modelMatrix: this.modelMatrix,
                        minimumPixelSize: 256,
                        maximumScale: 200000,
                        scale: 1,
                    }));
                globalViewer.scene.camera.flyTo({
                    destination: gCesium.Cartesian3.fromDegrees(121.631426, 24.018333, 160),
                    orientation: {
                        heading: gCesium.Math.toRadians(10),
                        pitch: gCesium.Math.toRadians(-30),
                        roll: gCesium.Math.toRadians(0)
                    },
                });
            }
            else if (this.selectedLayer == 'D') {
                var origin = gCesium.Cartesian3.fromDegrees(121.631459, 24.019816, 130);
                var heading = 0;
                var pitch = 0;
                var roll = 0;
                var hpr = new gCesium.HeadingPitchRoll(heading, pitch, roll);
                var modelMatrix = gCesium.Transforms.headingPitchRollToFixedFrame(origin, hpr);
                globalViewer.scene.primitives.add(await gCesium.Model.fromGltfAsync({
                    url: "Module/wm260_glb_0909.glb",
                    show: true,
                    modelMatrix: modelMatrix,
                    minimumPixelSize: 256,
                    maximumScale: 200000,
                    scale: 1,
                }));
                globalViewer.scene.camera.flyTo({
                    destination: gCesium.Cartesian3.fromDegrees(121.631226, 24.018333, 210),
                    orientation: {
                        heading: gCesium.Math.toRadians(10),
                        pitch: gCesium.Math.toRadians(-30),
                        roll: gCesium.Math.toRadians(0)
                    },
                });
            }
            //console.log(e)
        },
        onViewerReady({ Cesium, viewer }) {
            var origin = Cesium.Cartesian3.fromDegrees(121.631889, 24.020053, 0);
            var heading = 0.8;
            var pitch = -4.55;
            var roll = -112.5;
            var hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
            //var modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(origin);
            //var modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(origin, hpr);
            this.modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(origin, hpr)
            Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiNGI2MmQ4Mi1mNzZlLTRiNmMtODcyMy01MTZjYzljMzkwYjMiLCJpZCI6MTIzMTAwLCJpYXQiOjE2NzUyOTk2NjR9.JgrVaxKgvUq7qqZwHiwN0P7-hHA1Ub2G-m6siD-hwMo";

            viewer.scene.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(121.631284, 24.019501, 280),
                orientation: {
                    heading: Cesium.Math.toRadians(10),
                    pitch: Cesium.Math.toRadians(-30),
                    roll: Cesium.Math.toRadians(0)
                },
            });
            globalViewer = viewer
            gCesium = Cesium
        },
        drawingsReadyDefault({ Cesium, viewer, cesiumObject }) {
            //console.log('繪製選項參數：', cesiumObject)
            window.vm = this
        },
        onlayerAdded(imageryLayer) {
            //console.log('onlayerAdded', imageryLayer);
            //console.log('onlayerAdded', imageryLayer._imageryProvider._credit)
            //if (typeof imageryLayer.vcId === "undefined") {
            loadedLayer.push(imageryLayer._layerIndex);
            var div = document.getElementById("vc-selectLayer");
            var br = document.createElement("br");
            if (imageryLayer._layerIndex == 1)
                div.appendChild(br.cloneNode(true));
            var checkbox = document.createElement("input");
            checkbox.type = "checkbox";
            checkbox.id = "imageryLayer_" + imageryLayer._layerIndex;
            var labelText = "";
            //if (typeof imageryLayer.vcId === "undefined") {
            //    labelText = imageryLayer.imageryProvider.credit.html;
            //    imageryLayer.show = !imageryLayer.show;
            //    checkbox.checked = false;
            //}
            //else {
            //    labelText = "目前底圖圖層";
            //    checkbox.checked = true;
            //}
            if (typeof imageryLayer.vcId === "undefined")
                labelText = imageryLayer.imageryProvider.credit.html;
            else {
                labelText = imageryLayer.vcId;
                //console.log('FromVC', imageryLayer);
                //const obj = JSON.parse(imageryLayer);
                //console.log(imageryLayer);
                //console.log(imageryLayer._vcParent);
                //console.log(globalViewer.imageryLayers.get(imageryLayer._layerIndex).imageryProvider);
                //if (imageryLayer._layerIndex == 7) {
                //    for (var key in imageryLayer) {
                //        var value = imageryLayer[key];
                //        console.log(key, value);
                //    }
                //}
            }
            //透過BingMapsImageryProvider增加的圖層,可加入這2行置換名稱
            //if (labelText.indexOf('Bing') >= 0)
            //    labelText = "Bing maps";

            if (imageryLayer._layerIndex != 1) {
                imageryLayer.show = !imageryLayer.show;
                checkbox.checked = false;
            }
            else {
                checkbox.checked = true;
            }
            //checkbox.setAttribute("onchange","viewer.imageryLayers.get("+i+").show = !viewer.imageryLayers.get("+i+").show ;");
            checkbox.onclick = function (e) {
                var imageryID = e.target.id.split("_")[1];
                //console.log(globalViewer.imageryLayers.get(imageryID));
                globalViewer.imageryLayers.get(imageryID).show = !globalViewer.imageryLayers.get(imageryID).show;
                //console.log(e.target.id, e.target.checked, globalViewer.imageryLayers.get(imageryID).show);
                //if (checkbox.checked) { //if the checkbox is now checked
                //    console.log("checked");
                //    textbox.disabled = false;
                //}
                //else {
                //    console.log("unchecked");
                //    textbox.disabled = true; //programmatically disable a textbox
                //}
            }
            var newlabel = document.createElement("Label");
            newlabel.setAttribute("for", checkbox.id);
            newlabel.innerHTML = labelText;
            div.appendChild(checkbox);
            div.appendChild(newlabel);
            div.appendChild(br.cloneNode(true));
            //}

        },
        clear() {
            this.$refs.measurementsRef4.clearAll()
        },
        beforeLoad(e) {
            //this.$refs.vcHtml.show=true;
        },
        toggle(drawingActionInstance) {
            this.$refs.measurementsRef4.toggleAction(drawingActionInstance.name)
        },
        onTilesetReady(tileset, viewer) {
            // const cartographic = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center)
            // const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, cartographic.height)
            // const offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 5)
            // const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3())
            // tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation)
            viewer.zoomTo(tileset)
            viewer.scene.globe.depthTestAgainstTerrain = true
            this.restoreCursorMove = 'auto'
            this.drawing = false
        },
        drawEvt(e, viewer) {
            //console.log(e)
            const restoreCursor = getComputedStyle(viewer.canvas).cursor
            if (e.finished) {
                this.drawing = false
                if (e.type === 'move') {
                    viewer.canvas.setAttribute('style', `cursor: ${this.restoreCursorMove}`)
                }
            } else {
                this.drawing = true
                if (e.type === 'move') {
                    viewer.canvas.setAttribute('style', 'cursor: move')
                }
                if (e.type === 'new') {
                    viewer.canvas.setAttribute('style', 'cursor: crosshair')
                }
            }
        },
        activeEvt(e, viewer) {
            //console.log(e)
            viewer.canvas.setAttribute('style', `cursor: ${e.isActive ? 'crosshair' : 'auto'}`)
            if (!e.isActive) {
                this.drawing = false
                this.restoreCursorMove = 'auto'
            }
        },
        editorEvt(e, viewer) {
            //console.log(e)
            if (e.type === 'move') {
                const restoreCursor = getComputedStyle(viewer.canvas).cursor
                viewer.canvas.setAttribute('style', 'cursor: move')
                this.drawing = true
            }
        },
        mouseEvt(e, viewer) {
            //console.log(e)
            const restoreCursor = getComputedStyle(viewer.canvas).cursor
            if (!this.drawing) {
                if (e.type === 'onmouseover') {
                    this.restoreCursorMove = restoreCursor
                    viewer.canvas.setAttribute('style', 'cursor: pointer')
                } else {
                    viewer.canvas.setAttribute('style', `cursor: ${this.restoreCursorMove || 'auto'}`)
                }
            }
        },
        unload() {
            this.$refs.measurementsRef.unload()
        },
        load() {
            this.$refs.measurementsRef.load()
        },
        reload() {
            this.$refs.measurementsRef.reload()
        },
        myEventHandler(e) {
            document.getElementById("vc-adjustBlock").style.top = (window.innerHeight - 330) + "px";
            document.getElementById("vc-selectBlock").style.top = "40px";
            document.getElementById("vc-selectLayer").style.top = "70px";
        }
    },
    mounted() {
        window.addEventListener("resize", this.myEventHandler);
        this.$nextTick(function () {
            document.getElementById("vc-adjustBlock").style.top = (window.innerHeight - 330) + "px";
            document.getElementById("vc-selectBlock").style.top = "40px";
            document.getElementById("vc-selectLayer").style.top = "70px";
        })
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.demo-viewer .el-row:last-child {
    margin-bottom: 0;
}

.vc-box {
    width: 200px;
    height: 25px;
    color: white;
    background-color: rgb(0, 0, 0);
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    /* IE 5-7 */
    filter: alpha(opacity=50);

    /* Netscape */
    -moz-opacity: 0.5;

    /* Safari 1.x */
    -khtml-opacity: 0.5;

    /* Good browsers */
    opacity: 0.5;
}

.demo-viewer .el-row {
    border-radius: 100px;
    align-items: baseline;
}

.demo-viewer .demo-toolbar {
    position: absolute;
    left: 1%;
    top: 1%;
    min-width: 185px;
    z-index: 100;
    color: #fff;
    background-color: rgba(0, 0, 0, .2);
}

.demo-vc-viewer .el-switch:first-child {
    margin-left: 20px;
}

.demo-vc-viewer .el-switch {
    margin: 20px 20px 20px 0;
}

.demo-vc-datasource-custom {
    .el-switch__label.is-active {
        color: #409eff;
    }

}

.block {
    padding: 30px 24px;
    overflow: hidden;
    border-bottom: solid 1px #eff2f6;

    &:last-child {
        border-bottom: none;
    }

}


.el-button {
    margin: 10px 10px 10px 0;

    &:first-child {
        margin-left: 20px;
    }

}

.el-switch {
    margin: 20px 20px 20px 0;

    &:first-child {
        margin-left: 20px;
    }

}

.el-switch__label {
    color: white;
}

.demo-vc-viewer .demo-viewer .el-row {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#vc-adjustBlock {
    color: rgb(11, 11, 11);
    top: 100px;
    left: 30px;
    position: absolute;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid #d3d3d3;
    border-radius: 25px;
    padding: 8px;
    text-align: center;
}

#vc-selectBlock {
    color: rgb(11, 11, 11);
    top: 100px;
    left: 30px;
    position: absolute;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    padding: 0px;
    text-align: center;
}

#vc-selectLayer {
    color: rgb(11, 11, 11);
    top: 105px;
    left: 30px;
    position: absolute;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    padding: 5px;
    text-align: left;
}

.vc-js-slider {
    cursor: pointer;
}

.vc-js-btn {
    background-image: linear-gradient(#42A1EC, #0070C9);
    border: none;
    color: white;
    padding: 4px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    min-width: 30px;
    cursor: pointer;
    border-radius: 4px;
}

.vc-js-btn:disabled {
    cursor: default;
    opacity: .3;
}

.vc-js-btn:hover {
    background-image: linear-gradient(#51A9EE, #147BCD);
    border-color: #1482D0;
    text-decoration: none;
}

.vc-js-btn:active {
    background-image: linear-gradient(#3D94D9, #0067B9);
    border-color: #006DBC;
    outline: none;
}

.vc-js-btn:focus {
    box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
    outline: none;
}

.vc-select {
    border: 1px solid #0067B9;
    border-radius: 3px;
    padding: 0 5px;
    line-height: 36px;
    height: 24px;
    box-shadow: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
    width: 200px;
}
</style>
