const locale_enUS = {
  name: "en-US",
  nativeName: "English (US)",
  vc: {
    loadError: "needs to be child of VcViewer",
    navigation: {
      compass: {
        outerTip: "Drag outer ring: rotate view. Double-click: reset view.",
        innerTip: "Drag inner gyroscope: free orbit. TIP: You can also free orbit by holding the CTRL key and dragging the map.",
        title: "Click and drag to rotate the camera."
      },
      zoomCotrol: {
        zoomInTip: "Zoom in",
        zoomResetTip: "Reset zoom",
        zoomOutTip: "Zoom out"
      },
      print: {
        printTip: "Viewer screenshot/print",
        printViewTitle: "VueCesium Print View",
        credit: "Map Credits",
        screenshot: "Screenshot"
      },
      myLocation: {
        myLocationTip: "Centre map at your current location",
        positioning: "Positioning...",
        fail: "Positioning failed",
        centreMap: "My Location",
        lat: "Lat",
        lng: "Lng",
        address: "Address"
      },
      statusBar: {
        lat: "Lat",
        lng: "Lng",
        zone: "ZONE",
        e: "E",
        n: "N",
        elev: "Elev",
        level: "Level",
        heading: "H",
        pitch: "P",
        roll: "R",
        cameraHeight: "CameraH",
        tip: "Click to switch the mouse display coordinates to UTM projection coordinates"
      }
    },
    navigationSm: {
      compass: {
        outerTip: "Drag outer ring: rotate view. Double-click: reset view."
      },
      zoomCotrol: {
        zoomInTip: "Zoom in",
        zoomBarTip: "Drag the bar up to zoom in, and down to zoom out.",
        zoomOutTip: "Zoom out"
      }
    },
    measurement: {
      expand: "Expand",
      collapse: "Collapse",
      editor: {
        move: "Move the point",
        insert: "Insert a point",
        remove: "Remove the point",
        removeAll: "Remove all the points"
      },
      distance: {
        tip: "Distance",
        drawingTipStart: "Click the left button to draw the starting point of the distance measurement.",
        drawingTipEnd: "Click the left mouse button to draw the end point of the distance measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      "component-distance": {
        tip: "Component Distance",
        drawingTipStart: "Click the left mouse button to draw the starting point of component distance measurement.",
        drawingTipEnd: "Click the left mouse button to draw the end point of the component distance measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      polyline: {
        tip: "Polyline Distance",
        drawingTipStart: "Click the left button to draw the first point.",
        drawingTipEnd: "Click the left button to draw the next point, and double-click the left button to end the measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      horizontal: {
        tip: "Horizontal Distance",
        drawingTipStart: "Click the left button to draw the first point.",
        drawingTipEnd: "Click the left button to draw the next point, and double-click the left button to end the measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      vertical: {
        tip: "Vertical Distance",
        drawingTipStart: "Click the left button to draw the starting point of the vertical distance measurement.",
        drawingTipEnd: "Click the left button to draw the end point of the vertical distance measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      height: {
        tip: "Height ",
        drawingTipStart: "Click the left button to draw the height measurement point.",
        drawingTipEnd: "Click the left button to draw the height measurement point.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      area: {
        tip: "Area",
        drawingTipStart: "Click the left button to draw the first point.",
        drawingTipEnd: "Click the left button to draw the next point, and double-click the left button to end the measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      point: {
        tip: "Point Coordinate",
        drawingTipStart: "Click the left button to draw the point coordinate measurement.",
        drawingTipEnd: "Click the left button to draw the point coordinate measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification.",
        lng: "lng:",
        lat: "lat:",
        height: "height:",
        slope: "slope:"
      },
      rectangle: {
        tip: "Rectangle",
        drawingTipStart: "Click the left button to draw the rectangle measurement.",
        drawingTipEnd: "Click the left button to draw the rectangle measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      regular: {
        tip: "Regular",
        drawingTipStart: "Click the left button to draw the regular measurement.",
        drawingTipEnd: "Click the left button to draw the regular measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      circle: {
        tip: "Circle",
        drawingTipStart: "Click the left button to draw the circle measurement.",
        drawingTipEnd: "Click the left button to draw the circle measurement.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      clear: {
        tip: "Clear measurement results"
      }
    },
    drawing: {
      expand: "Expand",
      collapse: "Collapse",
      editor: {
        move: "Move the point",
        insert: "Insert a point",
        remove: "Remove the point",
        removeAll: "Remove all the points"
      },
      pin: {
        tip: "Drawing pin",
        drawingTipStart: "Click the left button to draw a pin.",
        drawingTipEnd: "Click the left button to draw a pin.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      point: {
        tip: "Drawing point",
        drawingTipStart: "Click the left button to draw a point.",
        drawingTipEnd: "Click the left button to draw a point.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      polyline: {
        tip: "Drawing polyline",
        drawingTipStart: "Click the left button to draw the first point.",
        drawingTipEnd: "Click the left button to draw the next point, and double-click the left button to end the drawing.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      polygon: {
        tip: "Drawing polygon",
        drawingTipStart: "Click the left button to draw the first point.",
        drawingTipEnd: "Click the left button to draw the next point, and double-click the left button to end the drawing.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      rectangle: {
        tip: "Drawing rectangle",
        drawingTipStart: "Click the left button to draw the starting point of the rectangle.",
        drawingTipEnd: "Click the left button to draw the end point of the rectangle.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      circle: {
        tip: "Drawing circle",
        drawingTipStart: "Click the left button to draw the starting point of the circle.",
        drawingTipEnd: "Click the left button to draw the end point of the circle.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      regular: {
        tip: "Drawing regular",
        drawingTipStart: "Click the left button to draw the starting point of the regular.",
        drawingTipEnd: "Click the left button to draw the end point of the circle.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      clear: {
        tip: "Clear drawing results"
      }
    },
    analysis: {
      expand: "Expand",
      collapse: "Collapse",
      editor: {
        move: "Move the point",
        insert: "Insert a point",
        remove: "Remove the point",
        removeAll: "Remove all the points"
      },
      sightline: {
        tip: "Sightline",
        drawingTipStart: "Click the left button to draw the view point of the sightline analysis.",
        drawingTipEnd: "Click the left button to draw the next point, and double-click the left button to end the sightline analysis.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      viewshed: {
        tip: "Viewshed",
        drawingTipStart: "Click the left button to draw the view point of the viewshed analysis.",
        drawingTipEnd: "Click the left button to draw the end point of the viewshed analysis.",
        drawingTipEditing: "Move the mouse to modify the node, click the left button to confirm the modification,\nand click the right button to discard the modification."
      },
      clear: {
        tip: "Clear analysis results"
      }
    },
    overview: {
      show: "Show",
      hidden: "Hidden"
    }
  }
}

const locale_zhCN = {
  name: "zh-CN",
  nativeName: "中文(简体)",
  vc: {
    loadError: "加载失败，必须作为 VcViewer 的子组件加载。",
    navigation: {
      compass: {
        outerTip: "旋转视图：顺/逆时针方向拖拽罗盘外环。\n重置视图：双击罗盘外环。",
        innerTip: "翻转视图：由内环向外环拖拽罗盘。\n 或者按住 Ctrl 键的同时拖拽地图。",
        title: "按住鼠标拖拽旋转相机。"
      },
      zoomCotrol: {
        zoomInTip: "放大",
        zoomResetTip: "重置视图",
        zoomOutTip: "缩小"
      },
      print: {
        printTip: "场景截图/打印",
        printViewTitle: "打印预览",
        credit: "地图版权",
        screenshot: "场景截图"
      },
      myLocation: {
        myLocationTip: "定位您的位置",
        positioning: "定位中...",
        fail: "定位失败",
        centreMap: "我的位置",
        lat: "纬度",
        lng: "经度",
        address: "地址"
      },
      statusBar: {
        lat: "纬度",
        lng: "经度",
        zone: "带号",
        e: "X",
        n: "Y",
        elev: "高程",
        level: "层级",
        heading: "方位",
        pitch: "俯仰",
        roll: "侧翻",
        cameraHeight: "视高",
        tip: "点击切换鼠标显示坐标为 UTM 投影坐标"
      }
    },
    navigationSm: {
      compass: {
        outerTip: "旋转视图：顺/逆时针方向拖拽罗盘外环；重置视图：双击罗盘外环。"
      },
      zoomCotrol: {
        zoomInTip: "放大",
        zoomBarTip: "按住滑块向上放大，向下缩小。",
        zoomOutTip: "缩小"
      }
    },
    measurement: {
      expand: "展开",
      collapse: "收拢",
      editor: {
        move: "移动节点",
        insert: "插入节点",
        remove: "移除节点",
        removeAll: "移除所有节点"
      },
      distance: {
        tip: "距离量算",
        drawingTipStart: "单击左键绘制距离量算起点。",
        drawingTipEnd: "单击左键绘制距离量算终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      "component-distance": {
        tip: "三角量算",
        drawingTipStart: "单击左键绘制三角量算起点。",
        drawingTipEnd: "单击左键绘制三角量算终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      polyline: {
        tip: "折线距离量算",
        drawingTipStart: "单击左键绘制第一个点。",
        drawingTipEnd: "单击左键绘制下一个点，双击左键结束量算。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      horizontal: {
        tip: "水平距离量算",
        drawingTipStart: "单击左键绘制第一个点。",
        drawingTipEnd: "单击左键绘制下一个点，双击左键结束量算。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      vertical: {
        tip: "垂直距离量算",
        drawingTipStart: "单击左键绘制垂直距离量算起点。",
        drawingTipEnd: "单击左键绘制垂直距离量算终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      height: {
        tip: "地表高度量算",
        drawingTipStart: "单击左键绘制高度量算点。",
        drawingTipEnd: "单击左键绘制高度量算点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      area: {
        tip: "面积量算",
        drawingTipStart: "单击左键绘制第一个点。",
        drawingTipEnd: "点击左键绘制下一个点，双击左键结束量算。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      point: {
        tip: "坐标量算",
        drawingTipStart: "点击左键绘制坐标量算点。",
        drawingTipEnd: "点击左键绘制坐标量算点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。",
        lng: "经度：",
        lat: "纬度：",
        height: "高度：",
        slope: "坡度："
      },
      rectangle: {
        tip: "矩形量算",
        drawingTipStart: "单击左键绘制矩形量算起点。",
        drawingTipEnd: "单击左键绘制矩形量算终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      regular: {
        tip: "正多边形量算",
        drawingTipStart: "单击左键绘制正多边形量算起点。",
        drawingTipEnd: "单击左键绘制正多边形量算终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      circle: {
        tip: "圆形量算",
        drawingTipStart: "单击左键绘制圆形量算起点。",
        drawingTipEnd: "单击左键绘制圆形量算终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      clear: {
        tip: "清除量算结果"
      }
    },
    drawing: {
      expand: "展开",
      collapse: "收拢",
      editor: {
        move: "移动节点",
        insert: "插入节点",
        remove: "移除节点",
        removeAll: "移除所有节点"
      },
      pin: {
        tip: "绘制图示点",
        drawingTipStart: "点击左键绘制图示点。",
        drawingTipEnd: "点击左键绘制图示点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      point: {
        tip: "绘制点",
        drawingTipStart: "点击左键绘制点。",
        drawingTipEnd: "点击左键绘制点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      polyline: {
        tip: "绘制线",
        drawingTipStart: "单击左键绘制第一个点。",
        drawingTipEnd: "单击左键绘制下一个点，双击左键结束绘制。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      polygon: {
        tip: "绘制面",
        drawingTipStart: "单击左键绘制第一个点。",
        drawingTipEnd: "单击左键绘制下一个点，双击左键结束绘制。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      rectangle: {
        tip: "绘制矩形",
        drawingTipStart: "单击左键绘制矩形起点。",
        drawingTipEnd: "单击左键绘制矩形终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      circle: {
        tip: "绘制圆",
        drawingTipStart: "单击左键绘制圆形起点。",
        drawingTipEnd: "单击左键绘制圆形终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      regular: {
        tip: "绘制正多边形",
        drawingTipStart: "单击左键绘制正多边形起点。",
        drawingTipEnd: "单击左键绘制正多边形终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      clear: {
        tip: "清除绘制结果"
      }
    },
    analysis: {
      expand: "展开",
      collapse: "收拢",
      editor: {
        move: "移动节点",
        insert: "插入节点",
        remove: "移除节点",
        removeAll: "移除所有节点"
      },
      sightline: {
        tip: "通视分析",
        drawingTipStart: "单击左键绘制观测点。",
        drawingTipEnd: "单击左键绘制目标点，双击左键结束绘制。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      viewshed: {
        tip: "可视域分析",
        drawingTipStart: "单击左键绘制可视域分析起点。",
        drawingTipEnd: "单击左键绘制可视域分析终点。",
        drawingTipEditing: "移动鼠标修改节点，单击左键确定修改，单击右键放弃修改。"
      },
      clear: {
        tip: "清除分析结果"
      }
    },
    overview: {
      show: "显示鹰眼",
      hidden: "隐藏鹰眼"
    }
  }
}


const locale_zhTW = {
  name: "zh-TW",
  nativeName: "中文(繁體)",
  vc: {
    loadError: "載入失敗，必須作為 VcViewer 的子元件載入。",
    navigation: {
      compass: {
        outerTip: "旋轉視圖：順/逆時針方向拖拽羅盤外環。\n重置視圖：按兩下羅盤外環。",
        innerTip: "翻轉視圖：由內環向外環拖拽羅盤。\n 或者按住 Ctrl 鍵的同時拖拽地圖。",
        title: "按住滑鼠拖拽旋轉相機。"
      },
      zoomCotrol: {
        zoomInTip: "放大",
        zoomResetTip: "重置視圖",
        zoomOutTip: "縮小"
      },
      print: {
        printTip: "場景截圖/列印",
        printViewTitle: "預覽列印",
        credit: "地圖版權",
        screenshot: "場景截圖"
      },
      myLocation: {
        myLocationTip: "定位您的位置",
        positioning: "定位中...",
        fail: "定位失敗",
        centreMap: "我的位置",
        lat: "緯度",
        lng: "經度",
        address: "地址"
      },
      statusBar: {
        lat: "緯度",
        lng: "經度",
        zone: "帶號",
        e: "X",
        n: "Y",
        elev: "高程",
        level: "層級",
        heading: "方位",
        pitch: "俯仰",
        roll: "側翻",
        cameraHeight: "視高",
        tip: "點擊切換滑鼠顯示座標為 UTM 投影座標"
      }
    },
    navigationSm: {
      compass: {
        outerTip: "旋轉視圖：順/逆時針方向拖拽羅盤外環；重置視圖：按兩下羅盤外環。"
      },
      zoomCotrol: {
        zoomInTip: "放大",
        zoomBarTip: "按住滑塊向上放大，向下縮小。",
        zoomOutTip: "縮小"
      }
    },
    measurement: {
      expand: "展開",
      collapse: "收攏",
      editor: {
        move: "移動節點",
        insert: "插入節點",
        remove: "移除節點",
        removeAll: "移除所有節點"
      },
      distance: {
        tip: "距離量算",
        drawingTipStart: "按一下左鍵繪製距離量算起點。",
        drawingTipEnd: "按一下左鍵繪製距離量算終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      "component-distance": {
        tip: "三角量算",
        drawingTipStart: "按一下左鍵繪製三角量算起點。",
        drawingTipEnd: "按一下左鍵繪製三角量算終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      polyline: {
        tip: "折線距離量算",
        drawingTipStart: "按一下左鍵繪製第一個點。",
        drawingTipEnd: "按一下左鍵繪製下一個點，按兩下左鍵結束量算。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      horizontal: {
        tip: "水準距離量算",
        drawingTipStart: "按一下左鍵繪製第一個點。",
        drawingTipEnd: "按一下左鍵繪製下一個點，按兩下左鍵結束量算。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      vertical: {
        tip: "垂直距離量算",
        drawingTipStart: "按一下左鍵繪製垂直距離量算起點。",
        drawingTipEnd: "按一下左鍵繪製垂直距離量算終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      height: {
        tip: "地表高度量算",
        drawingTipStart: "按一下左鍵繪製高度量算點。",
        drawingTipEnd: "按一下左鍵繪製高度量算點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      area: {
        tip: "面積量算",
        drawingTipStart: "按一下左鍵繪製第一個點。",
        drawingTipEnd: "點擊左鍵繪製下一個點，按兩下左鍵結束量算。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      point: {
        tip: "座標量算",
        drawingTipStart: "點擊左鍵繪製座標量算點。",
        drawingTipEnd: "點擊左鍵繪製座標量算點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。",
        lng: "經度：",
        lat: "緯度：",
        height: "高度：",
        slope: "坡度："
      },
      rectangle: {
        tip: "矩形量算",
        drawingTipStart: "按一下左鍵繪製矩形量算起點。",
        drawingTipEnd: "按一下左鍵繪製矩形量算終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      regular: {
        tip: "正多邊形量算",
        drawingTipStart: "按一下左鍵繪製正多邊形量算起點。",
        drawingTipEnd: "按一下左鍵繪製正多邊形量算終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      circle: {
        tip: "圓形量算",
        drawingTipStart: "按一下左鍵繪製圓形量算起點。",
        drawingTipEnd: "按一下左鍵繪製圓形量算終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      clear: {
        tip: "清除量算結果"
      }
    },
    drawing: {
      expand: "展開",
      collapse: "收攏",
      editor: {
        move: "移動節點",
        insert: "插入節點",
        remove: "移除節點",
        removeAll: "移除所有節點"
      },
      pin: {
        tip: "繪製圖示點",
        drawingTipStart: "點擊左鍵繪製圖示點。",
        drawingTipEnd: "點擊左鍵繪製圖示點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      point: {
        tip: "繪製點",
        drawingTipStart: "點擊左鍵繪製點。",
        drawingTipEnd: "點擊左鍵繪製點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      polyline: {
        tip: "繪製線",
        drawingTipStart: "按一下左鍵繪製第一個點。",
        drawingTipEnd: "按一下左鍵繪製下一個點，按兩下左鍵結束繪製。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      polygon: {
        tip: "繪製面",
        drawingTipStart: "按一下左鍵繪製第一個點。",
        drawingTipEnd: "按一下左鍵繪製下一個點，按兩下左鍵結束繪製。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      rectangle: {
        tip: "繪製矩形",
        drawingTipStart: "按一下左鍵繪製矩形起點。",
        drawingTipEnd: "按一下左鍵繪製矩形終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      circle: {
        tip: "繪製圓",
        drawingTipStart: "按一下左鍵繪製圓形起點。",
        drawingTipEnd: "按一下左鍵繪製圓形終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      regular: {
        tip: "繪製正多邊形",
        drawingTipStart: "按一下左鍵繪製正多邊形起點。",
        drawingTipEnd: "按一下左鍵繪製正多邊形終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      clear: {
        tip: "清除繪製結果"
      }
    },
    analysis: {
      expand: "展開",
      collapse: "收攏",
      editor: {
        move: "移動節點",
        insert: "插入節點",
        remove: "移除節點",
        removeAll: "移除所有節點"
      },
      sightline: {
        tip: "通視分析",
        drawingTipStart: "按一下左鍵繪製觀測點。",
        drawingTipEnd: "按一下左鍵繪製目標點，按兩下左鍵結束繪製。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      viewshed: {
        tip: "可視域分析",
        drawingTipStart: "按一下左鍵繪製可視域分析起點。",
        drawingTipEnd: "按一下左鍵繪製可視域分析終點。",
        drawingTipEditing: "移動滑鼠修改節點，按一下左鍵確定修改，按一下右鍵放棄修改。"
      },
      clear: {
        tip: "清除分析結果"
      }
    },
    overview: {
      show: "顯示鷹眼",
      hidden: "隱藏鷹眼"
    }
  }
}

export {locale_enUS,locale_zhCN,locale_zhTW};