import { createRouter, createWebHistory } from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'
import VueCesiumApp from '../components/VueCesium.vue'
//import VueCesiumAppDraw from '../components/VueCesiumDraw.vue'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			component: HelloWorld,
			props: {msg:"Welcome to this Vue.js App"}
		},
		{
			path: '/2d',
			component: () => import('../components/VueCesiumDraw.vue')
		},
		{
			path: '/3d',
			component: VueCesiumApp
		},
	],
})

export default router