<template>
    <JqxMenu ref="Appmenu" :mode="mode" :enableHover="enableHover" :width="600" :height="30">
        <ul>
            <li><a href="/">Home</a></li>
            <li>
                Solutions
                <ul style="width: 200px;">
                    <li><a href="/2d">Cesium 2D</a></li>
                    <li><a href="/3d">Cesium 3D</a></li>
                    <!--<li><a href="#Government">Government</a></li>
                        <li><a href="#Manufacturing">Manufacturing</a></li>
                        <li type="separator"></li>
                        <li>
                            Software Solutions
                            <ul style="width: 220px;">
                                <li><a href="#ConsumerPhoto">Consumer photo and video</a></li>
                                <li><a href="#Mobile">Mobile</a></li>
                                <li><a href="#RIA">Rich Internet applications</a></li>
                                <li><a href="#TechnicalCommunication">Technical communication</a></li>
                                <li><a href="#Training">Training and eLearning</a></li>
                                <li><a href="#WebConferencing">Web conferencing</a></li>
                            </ul>
                        </li>
                        <li><a href="#">All industries and solutions</a></li>-->
                </ul>
            </li>
        </ul>
    </JqxMenu>
    <!--<VueCesiumApp />-->
    <!--<VueCesiumAppDraw />-->
    <router-view />
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import VueCesiumApp from './components/VueCesium.vue'
//import VueCesiumAppDraw from './components/VueCesiumDraw.vue'
import JqxMenu from "jqwidgets-scripts/jqwidgets-vue/vue_jqxmenu.vue";

export default {
    name: 'App',
    components: {
        JqxMenu,
        //HelloWorld,
        //VueCesiumApp,
        //VueCesiumAppDraw
    },
    data: function () {
        return {
            mode: 'horizontal',
            enableHover: false
        }
    }
}
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
